var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[_vm.navIsVisible ? 'is-navPanel-visible noprint' : 'noprint']},[_c('nav',{attrs:{"id":"nav"}},[_c('ul',{staticClass:"links"},[_c('router-link',{attrs:{"to":"/main"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:['nav-application', isActive && 'active']},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_vm._v(_vm._s(_vm.ghostSettings.title))])])]}}])}),_c('router-link',{attrs:{"to":"/regular"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:['nav-application', isActive && 'active']},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_vm._v("Regular Eyes")])])]}}])}),_c('router-link',{attrs:{"to":"/complex"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:['nav-application', isActive && 'active']},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_vm._v("Complex Eyes")])])]}}])}),_c('router-link',{attrs:{"to":"/second"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:['nav-application', isActive && 'active']},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_vm._v("Second Eyes")])])]}}])})],1),_c('ul',{staticClass:"links"},_vm._l((_vm.ghostSettings.navigation.slice(0, 2)),function(item){return _c('li',{key:item.label,staticClass:"nav-news"},[_c('a',{attrs:{"href":_vm.ghostSettings.url + item.url}},[_vm._v(_vm._s(item.label))])])}),0)]),_c('a',{class:[_vm.scrolled ? 'alt' : ''],attrs:{"href":"#navPanel","id":"navPanelToggle"},on:{"click":function($event){_vm.navIsVisible = !_vm.navIsVisible}}},[_vm._v(" Menu")]),_c('div',{attrs:{"id":"navPanel"}},[_c('nav',[_c('ul',{staticClass:"links"},[_c('router-link',{attrs:{"to":"/main"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:['nav-application', isActive && 'active']},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_vm._v(_vm._s(_vm.ghostSettings.title))])])]}}])}),_c('router-link',{attrs:{"to":"/regular"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:['nav-application', isActive && 'active']},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_vm._v("Regular Eyes")])])]}}])}),_c('router-link',{attrs:{"to":"/complex"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:['nav-application', isActive && 'active']},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_vm._v("Complex Eyes")])])]}}])}),_c('router-link',{attrs:{"to":"/second"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:['nav-application', isActive && 'active']},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_vm._v("Second Eyes")])])]}}])}),_vm._l((_vm.ghostSettings.navigation.slice(0, 2)),function(item){return _c('li',{key:item.label,staticClass:"nav-news"},[_c('a',{attrs:{"href":_vm.ghostSettings.url + item.url}},[_vm._v(_vm._s(item.label))])])})],2)]),_c('a',{staticClass:"close",attrs:{"href":"#navPanel"},on:{"click":function($event){_vm.navIsVisible = !_vm.navIsVisible}}})])])}
var staticRenderFns = []

export { render, staticRenderFns }