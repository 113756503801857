<style scoped>
@font-face {
  font-family: "Source Sans Pro";
  src: url("https://fonts.gstatic.com/s/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3iu4nwlxdu.woff2")
    format("woff2");
  font-style: normal;
  font-weight: 900;
  unicode-range: U+0-FF, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

#nav {
  color: #fff;
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-transition: -moz-transform 1s ease, opacity 1s ease;
  -webkit-transition: -webkit-transform 1s ease, opacity 1s ease;
  -ms-transition: -ms-transform 1s ease, opacity 1s ease;
  transition: transform 1s ease, opacity 1s ease;
  background: rgba(255, 255, 255, 0.175);
  height: 4rem;
  line-height: 4rem;
  margin: -4rem auto 0 auto;
  overflow: hidden;
  padding: 0 2rem 0 0;
  position: relative;
  width: calc(100% - 4rem);
  max-width: 72rem;
  z-index: 2;
}
@media screen and (max-width: 736px) {
  #nav {
    display: none;
  }
}
#nav ul.links {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-grow: 1;
  -webkit-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  -moz-flex-shrink: 1;
  -webkit-flex-shrink: 1;
  -ms-flex-shrink: 1;
  flex-shrink: 1;
  font-family: "Source Sans Pro", Helvetica, sans-serif;
  font-weight: 900;
  letter-spacing: 0.075em;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
  text-transform: uppercase;
}
#nav ul.links li.active a:hover {
  color: #18bfef !important;
}
ul.icons li {
  display: inline-block;
  padding: 0 0.5rem 0 0;
  vertical-align: middle;
}
#nav a {
  color: #fff;
  border-bottom-color: rgba(255, 255, 255, 0.5);
  text-decoration: none;
}
.icon {
  text-decoration: none;
  border-bottom: none;
  position: relative;
}
.icon::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-transform: none !important;
}
.icon > .label {
  display: none;
}
ul.icons li {
  display: inline-block;
  padding: 0 0.5rem 0 0;
  vertical-align: middle;
}
#nav ul.links li a {
  -moz-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  -ms-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  display: block;
  font-size: 18px;
  font-weight: 300;
  outline: none;
  padding: 0 2rem;
}
#nav ul.links li a:hover {
  color: inherit !important;
  background-color: rgba(255, 255, 255, 0.1);
}
#nav ul.links li.active a:hover {
  color: #18bfef !important;
}
#nav ul.links li a {
  -moz-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  -ms-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  display: block;
  outline: none;
  padding: 0 2rem;
  font-size: 0.8rem;
  font-weight: 300;
}
#nav ul.links li.active {
  background-color: #fff;
}
#nav ul.links li.active a {
  color: #1e252d;
}
ul.icons li .icon::before {
  width: 2.25rem;
  height: 2.25rem;
  line-height: 2.25rem;
  display: inline-block;
  text-align: center;
  border-radius: 100%;
  font-size: 1.25rem;
}

/* MENU */
#navPanelToggle {
  text-decoration: none;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
  -ms-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
  display: none;
  position: fixed;
  top: 0.75rem;
  right: 0.75rem;
  border: 0;
  border-radius: 5px;
  height: 25px;
  color: #fff;
  font-family: "Source Sans Pro", Helvetica, sans-serif;
  font-size: 0.9rem;
  font-weight: 900;
  letter-spacing: 0.075em;
  padding: 0.375rem 1.25rem;
  text-transform: uppercase;
  z-index: 10001;
}
@media screen and (max-width: 736px) {
  #navPanelToggle {
    display: block;
  }
}
#navPanelToggle::before {
  content: "\f0c9";
  /* margin-right: 0.5rem; */
}
#navPanelToggle::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-transform: none !important;
}
#navPanelToggle.alt {
  background-color: rgba(255, 255, 255, 0.875);
  box-shadow: 0 0.125rem 0.75rem 0 rgba(30, 37, 45, 0.25);
  color: #212931;
}
#navPanelToggle {
    font-size: 0.8rem;
    padding: 0.25rem 1rem;
}
/* Nav Panel */
#navPanel {
  -moz-transform: translateX(20rem);
  -webkit-transform: translateX(20rem);
  -ms-transform: translateX(20rem);
  transform: translateX(20rem);
  -moz-transition: -moz-transform 0.5s ease, box-shadow 0.5s ease,
    visibility 0.5s;
  -webkit-transition: -webkit-transform 0.5s ease, box-shadow 0.5s ease,
    visibility 0.5s;
  -ms-transition: -ms-transform 0.5s ease, box-shadow 0.5s ease, visibility 0.5s;
  transition: transform 0.5s ease, box-shadow 0.5s ease, visibility 0.5s;
  display: none;
  -webkit-overflow-scrolling: touch;
  background: #fff;
  box-shadow: none;
  color: #212931;
  height: 100%;
  max-width: 80%;
  overflow-y: auto;
  padding: 3rem 2rem;
  position: fixed;
  right: 0;
  top: 0;
  visibility: hidden;
  width: 20rem;
  z-index: 10002;
}

@media screen and (max-width: 736px) {
  #navPanel {
    display: block;
  }
  .is-navPanel-visible #navPanel {
    -moz-transform: translateX(0);
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    box-shadow: 0 0 1.5rem 0 rgba(0, 0, 0, 0.2);
    visibility: visible;
  }
}
#navPanel .close::before {
  content: "\f00d";
  font-size: 1.25rem;
}
#navPanel .close::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-transform: none !important;
}
#navPanel {
  color: #212931;
  visibility: hidden;
}
#navPanel .links li a {
  border-bottom: 0;
  display: block;
  font-family: "Source Sans Pro", Helvetica, sans-serif;
  font-size: 0.9rem;
  font-size: 0.9rem;
  font-weight: 900;
  letter-spacing: 0.075em;
  padding: 0.75rem 0;
  text-transform: uppercase;
}
a:hover {
  border-bottom-color: transparent;
  color: #18bfef !important;
}
#navPanel .close {
  text-decoration: none;
  -moz-transition: color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out;
  -ms-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  border: 0;
  color: #909498;
  cursor: pointer;
  display: block;
  height: 3.25rem;
  line-height: 3.25rem;
  padding-right: 1.25rem;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
  width: 7rem;
}
#navPanel .links {
  list-style: none;
  padding-left: 0;
}
a {
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
    border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
    border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  -ms-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
    border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
    border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  border-bottom: dotted 1px;
  text-decoration: none;
  color: black;
}
#navPanel .links li {
  border-top: solid 2px #eee;
}
#navPanel .links li:first-child {
  border-top: 0;
}
#navPanel .links li a {
  border-bottom: 0;
  display: block;
  font-family: "Source Sans Pro", Helvetica, sans-serif;
  font-size: 0.9rem;
  font-size: 0.9rem;
  font-weight: 900;
  letter-spacing: 0.075em;
  padding: 1.25rem 0;
  text-transform: uppercase;
}
ul li {
  padding-left: 0.5rem;
}
ul.icons.alt li .icon::before {
  box-shadow: inset 0 0 0 2px #eee;
}
ul.icons {
  cursor: default;
  list-style: none;
  padding-left: 0;
}
ul {
  list-style: disc;
  margin: 0 0 2rem 0;
  padding-left: 1rem;
}
</style>

<template>
  <div :class="[navIsVisible ? 'is-navPanel-visible noprint' : 'noprint']">
    <nav id="nav">
      <ul class="links">
        <router-link to="/main" v-slot="{ href, navigate, isActive }">
          <li :class="['nav-application', isActive && 'active']">
            <a :href="href" @click="navigate">{{ ghostSettings.title }}</a>
          </li>
        </router-link>
        <router-link to="/regular" v-slot="{ href, navigate, isActive }">
          <li :class="['nav-application', isActive && 'active']">
            <a :href="href" @click="navigate">Regular Eyes</a>
          </li>
        </router-link>
        <router-link to="/complex" v-slot="{ href, navigate, isActive }">
          <li :class="['nav-application', isActive && 'active']">
            <a :href="href" @click="navigate">Complex Eyes</a>
          </li>
        </router-link>
        <router-link to="/second" v-slot="{ href, navigate, isActive }">
          <li :class="['nav-application', isActive && 'active']">
            <a :href="href" @click="navigate">Second Eyes</a>
          </li>
        </router-link>
      </ul>
      <ul class="links">
        <li
          v-for="item in ghostSettings.navigation.slice(0, 2)"
          class="nav-news"
          :key="item.label"
        >
          <a :href="ghostSettings.url + item.url">{{ item.label }}</a>
        </li>
      </ul>
    </nav>
    <a
      href="#navPanel"
      id="navPanelToggle"
      :class="[scrolled ? 'alt' : '']"
      @click="navIsVisible = !navIsVisible"
      > Menu</a
    >
    <div id="navPanel">
      <nav>
        <ul class="links">
          <router-link to="/main" v-slot="{ href, navigate, isActive }">
            <li :class="['nav-application', isActive && 'active']">
              <a :href="href" @click="navigate">{{ ghostSettings.title }}</a>
            </li>
          </router-link>
          <router-link to="/regular" v-slot="{ href, navigate, isActive }">
            <li :class="['nav-application', isActive && 'active']">
              <a :href="href" @click="navigate">Regular Eyes</a>
            </li>
          </router-link>
          <router-link to="/complex" v-slot="{ href, navigate, isActive }">
            <li :class="['nav-application', isActive && 'active']">
              <a :href="href" @click="navigate">Complex Eyes</a>
            </li>
          </router-link>
          <router-link to="/second" v-slot="{ href, navigate, isActive }">
            <li :class="['nav-application', isActive && 'active']">
              <a :href="href" @click="navigate">Second Eyes</a>
            </li>
          </router-link>
          <li
            v-for="item in ghostSettings.navigation.slice(0, 2)"
            class="nav-news"
            :key="item.label"
          >
            <a :href="ghostSettings.url + item.url">{{ item.label }}</a>
          </li>
        </ul>
      </nav>
      <a
        href="#navPanel"
        class="close"
        @click="navIsVisible = !navIsVisible"
      ></a>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data () {
    return {
      items: {},
      navIsVisible: false,
      scrolled: false
    }
  },
  created () {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll (event) {
      this.scrolled = window.scrollY > 250
    }
  },
  props: {
    actions: Array
  },
  computed: {
    isLoggedIn () {
      return this.$store.getters.isLoggedIn
    },
    ...mapState(['ghostSettings'])
  }
}
</script>
